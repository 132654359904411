<template>
  <div>
    <div id="login-container">
      <div class="login-card">
        <div class="form-container">
          <div class="header">ورود اطلاعات</div>
          <img class="liner" src="../../assets/images/Line-login.svg" alt="" />
          <form class="login-form" @submit.prevent>
            <label
              class="input-label"
              :class="[
                this.message.first_name ? 'error' : '',
                this.$store.state.status === 'first_name' ? 'error' : ''
              ]"
              for=""
            >
              <input
                v-model="user.first_name"
                type="text"
                name=""
                placeholder="نام"
                @input="firstName"
              />
            </label>
            <div
              v-if="
                this.$store.state.status === 'first_name' || message.first_name
              "
              class="error-massage"
            >
              {{ this.$store.state.message || this.message.first_name }}
            </div>
            <label
              class="input-label"
              for=""
              :class="[
                this.$store.state.status === 'last_name' ? 'error' : '',
                this.message.last_name ? 'error' : ''
              ]"
            >
              <input
                v-model="user.last_name"
                type="text"
                name=""
                @input="lastName"
                placeholder="نام خانوادگی"
              />
            </label>
            <div
              v-if="
                this.$store.state.status === 'last_name' ||
                  this.message.last_name
              "
              class="error-massage"
            >
              {{ this.$store.state.message || this.message.last_name }}
            </div>
            <label
              class="input-label"
              for=""
              :class="[
                this.$store.state.status === 'email' ? 'error' : '',
                this.message.email ? 'error' : ''
              ]"
            >
              <input
                autocomplete="chrome-off"
                v-model="user.email"
                type="email"
                name=""
                placeholder="ایمیل"
              />
            </label>
            <div
              v-if="this.$store.state.status === 'email' || message.email"
              class="error-massage"
            >
              {{ this.$store.state.message || this.message.email }}
            </div>
            <label
              class="input-label"
              for=""
              :class="[
                this.$store.state.status === 'password' ? 'error' : '',
                this.message.password ? 'error' : ''
              ]"
            >
              <input
                autocomplete="off"
                v-model="user.password"
                type="password"
                name=""
                placeholder="کلمه عبور"
              />
            </label>
            <div
              v-if="this.$store.state.status === 'password' || message.password"
              class="error-massage"
            >
              {{ this.$store.state.message || this.message.password }}
            </div>
            <label
              class="input-label"
              for=""
              :class="[
                this.$store.state.status === 'password' ? 'error' : '',
                this.message.confirm_password ? 'error' : ''
              ]"
            >
              <input
                autocomplete="off"
                v-model="user.password_confirm"
                type="password"
                name=""
                id=""
                placeholder="تکرار کلمه ی عبور"
              />
            </label>
            <div
              v-if="
                this.$store.state.status === 'password' ||
                  message.confirm_password
              "
              class="error-massage"
            >
              {{ this.$store.state.message || this.message.confirm_password }}
            </div>
            <button @click="register" class="btn-singup">ثبت نام</button>
          </form>
        </div>
        <img
          class="login-image"
          src="../../assets/images/auth-image.svg"
          height="502"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirm: ""
      },
      message: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        confirm_password: ""
      },
      reg: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      color: "#ffffff",
      size: "40px"
    };
  },
  methods: {
    register() {
      this.message = {};
      if (
        this.validationFirstName(this.user.first_name) &&
        this.validationLastName(this.user.last_name) &&
        this.validateEmail(this.user.email) &&
        this.validationPassword(this.user.password, this.user.password_confirm)
      ) {
        let data = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email,
          password: this.user.password,
          password_confirmation: this.user.password_confirm
        };
        this.$store
          .dispatch("register", data)
          .then(() => {
            this.$router.push("/dashboard");
          })
          .catch(error => console.log(error));
      }
      if (this.user.first_name.length < 3) {
        this.message.first_name = "نام باید بیشتر از سه کارکتر باشد";
      }
      if (!this.user.first_name) {
        this.message.first_name = "نام را باید وارد کنید";
      }
      if (this.user.last_name.length < 2) {
        this.message.last_name = "نام خانوادگی باید بیشتر از دو کارکتر باشد";
      }
      if (!this.user.last_name) {
        this.message.last_name = "نام خانوادگی نباید خالی باشد";
      }
      if (this.user.password !== this.user.password_confirm) {
        this.message.confirm_password = "رمز عبور با تکرار آن مطابقت ندارد";
      }
      if (this.user.password.length < 6) {
        this.message.password = "رمز عبور باید بیشتر از 6 کارکتر باشد";
      }
      if (!this.user.password) {
        this.message.password = "لطفا رمز عبور را وارد کنید";
      }
      if (!this.user.password_confirm) {
        this.message.confirm_password = "فیلد تکرار رمز عبور نباید خالی باشد";
      }
      if (!this.reg.test(this.user.email)) {
        this.message.email = "ایمیل معتبر نمیباشد";
      }
      if (!this.user.email) {
        this.message.email = "ایمیل باید وارد شود";
      }
    },
    firstName(char) {
      if (char.data) {
        if (/^[a-zA-Z0-9]+$/.test(char.data)) {
          this.message.first_name =
            "از عدد استفاده نکنید و زبان کیبورد باید فارسی باشد";
        } else {
          this.message.first_name = "";
        }
      }
    },
    lastName(char) {
      if (char.data) {
        if (/^[a-zA-Z0-9]+$/.test(char.data)) {
          this.message.last_name =
            "از عدد استفاده نکنید و زبان کیبورد باید فارسی باشد";
        } else {
          this.message.last_name = "";
        }
      }
    },
    validateEmail(value) {
      return this.reg.test(value) && value.length > 0;
    },
    validationFirstName(value) {
      // console.log(value);
      return value.length >= 3 && value.length > 0;
    },
    validationLastName(value) {
      return value.length >= 2 && value.length > 0;
    },
    validationPassword(password, confirm) {
      return (
        password.length >= 6 && password.length > 0 && password === confirm
      );
    }
  }
};
</script>
<style scoped>
::placeholder {
  color: rgb(248, 241, 241);
  opacity: 1;
}

.loading {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

#login-container {
  width: 100%;
  height: 100vh;
  background: linear-gradient(75.38deg, #5c6699 0%, #070d59 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-card {
  background: #1f3c88;
  border-radius: 20px;
  width: 1052px;
  height: 545px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.login-image {
  position: absolute;
  left: -14%;
  top: -18%;
  width: 70%;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 88%;
  margin: 6%;
  max-width: 400px;
  margin-top: 9px;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 44px;
  color: #fff;
  margin-bottom: 10px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-label {
  display: flex;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  width: 350px;
  height: 30px;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 12px;
}

.input-label input {
  background-color: transparent;
  border: none;
  height: 100%;
  outline: none;
  width: calc(100% - 35px);
  padding-right: 10px;
  color: #ffff;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.input-label img {
  height: 24px;
  padding-right: 10px;
}

.input-password {
  background: #ffffff;
  opacity: 0.2;
  border-radius: 5px;
  outline-style: none;
  border: none;
  width: 350px;
  height: 30px;
}

.liner {
  margin-bottom: 6px;
}

.btn-singup {
  font-family: "Vazir Medium FD";
  border: none;
  background: #ffffff;
  border-radius: 10px;
  width: 285px;
  height: 40px;
  margin-top: 28px;
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 22px;
  line-height: 34px;
  color: #070d59;
  cursor: pointer;
}

.error {
  border: 1px solid #ff6b6b;
}

.error-massage {
  margin-top: -9px;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #ff6b6b;
  text-align: right;
  cursor: pointer;
  align-self: flex-start;
}

/* responsive section */
@media only screen and (max-width: 320px) {
  #login-container {
    padding: 0 10px;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
    margin-top: 70px;
  }

  .form-container {
    min-width: 100%;
    margin: 0 auto;
  }

  .login-image {
    display: none;
  }

  .header {
    font-size: 1.5em;
  }

  .liner {
    width: 100%;
  }

  .input-label {
    width: 80%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .btn-singup {
    width: 70%;
    font-size: 16px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .login-form {
    width: 100%;
  }
}

@media (min-width: 321px) and (max-width: 480px) {
  #login-container {
    padding: 0 10px;
  }

  .form-container {
    margin: 0 auto;
  }

  .login-card {
    width: 100%;
  }

  .header {
    font-size: 1.5em;
  }

  .input-label {
    width: 100%;
  }

  .input-label input {
    font-size: 0.7em;
  }

  .input-label-error input {
    font-size: 0.7em;
  }

  .input-label img {
    width: 30px;
  }

  .error-massage {
    font-size: 0.5em;
  }

  .liner {
    width: 100%;
  }

  .btn-singup {
    width: 80%;
    font-size: 1em;
  }

  .login-form {
    width: 100%;
  }
}

@media only screen and (max-width: 1140px) {
  .login-image {
    left: -7%;
    top: -13%;
    width: 60%;
  }

  #login-container {
    padding: 0 70px;
  }
}

@media only screen and (max-width: 960px) {
  .login-image {
    left: -11%;
    width: 60%;
    top: -8%;
  }

  #login-container {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 768px) {
  .login-image {
    display: none;
  }

  #login-container {
    padding: 0 10px;
  }

  .login-card {
    justify-content: center;
  }
}
</style>
